import React from 'react';

import { Link } from '@constellation/core';
import { AppContent } from 'content/AppContent';

const howToMakeAPayment: AppContent['howToMakeAPayment'] = {
  pageHeading: 'How to make a payment',
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Before you start',
        sectionBody: [
          {
            text: "You must be named on the mortgage to make a payment. Payments can't be made by credit card or debit Mastercards (cards beginning with a 5).",
          },
        ],
      },
      {
        sectionHeading: 'Direct Debit',
        sectionBody: [
          {
            text: (
              <>
                To set up your Direct Debit from your current account over the
                phone, please call us on{' '}
                <Link href="tel:0345 300 2627">0345 300 2627</Link>.
              </>
            ),
          },
          {
            boldText: "Once your Direct Debit has been set up, we'll:",
          },
          {
            list: [
              'write to you to confirm the details ',
              'forward your instructions to your bank authorising them take payments',
              'write to let you know if your mortgage payments change for any reason.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Pay by bank transfer or set up a standing order',
        sectionBody: [
          {
            boldText:
              'You can pay by bank transfer or set up a standing order using these details:',
          },
          {
            list: [
              'Sort code: 30-00-00',
              'Account number: 00332275',
              'Account name: Lloyds Bank plc',
              "Reference: your 14-digit mortgage account number followed by '00'.",
            ],
          },
          {
            text: "If you want to pay a specific sub-account on your mortgage, replace '00' with the last two digits of the sub-account's number.",
          },
        ],
      },
      {
        sectionHeading: 'Pay over the phone',
        sectionBody: [
          {
            text: (
              <>
                To make a payment by phone, call our automated phone line on{' '}
                <Link href="tel:0345 300 2627">0345 300 2627</Link>.
              </>
            ),
          },
          {
            text: "You'll need your 14-digit mortgage account number to make the payment.",
          },
        ],
      },
      {
        sectionHeading: `The Direct Debit Guarantee `,
        sectionBody: [
          {
            list: [
              'If there are any changes to the amount, date or frequency of your Direct Debit, Birmingham Midshires will notify you in advance of your account being debited or as otherwise agreed. ',
              'If you request Birmingham Midshires to collect a payment, confirmation of the amount and date will be given to you at the time of the request.',
              "If an error is made in the payment of your Direct Debit, by Birmingham Midshires, a division of Bank of Scotland plc or your bank or building society, you're entitled to a full and immediate refund of the amount paid from your bank or building society.",
              "If you receive a refund you're not entitled to, you must pay it back when Birmingham Midshires asks you to.",
              'You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation might be required. Please also notify us. ',
            ],
          },
        ],
      },
    ],
  },
  payByCheque: {
    label: 'Pay by cheque',
    firstParagraph:
      "Please make all cheques payable to 'Birmingham Midshires'.",
    secondParagraph:
      "On the back, write your 14-digit mortgage account number plus an extra '00' on the end, so it's 16-digits in total.",
    postTo: 'Post to:',
    address: {
      businessName: 'Birmingham Midshires Mortgage Servicing',
      poBox: 'PO Box 833',
      city: 'Leeds',
      postcode: 'LS1 9PU',
    },
    thirdParagraph:
      'Your payment will be applied on the date we receive the cheque and will usually show on your mortgage account the following day.',
  },
  internationalPayments: {
    label: 'International payments',
    ibanLabel: 'IBAN No: ',
    ibanNumber: 'GB19 LOYD 3015 9900 5800 06',
    firstParagraph:
      "Use your 14-digit mortgage account number followed by '00' as the reference when making the payment.",
  },
};

export default howToMakeAPayment;
