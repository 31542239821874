import { TableCol, Table, Text } from "@constellation/core";
import styled, { StyledComponent } from "styled-components";


export const StyledTableCol: StyledComponent<typeof TableCol, any> = styled(TableCol)(
  ({ theme }) => `
  &&&&& {
    margin-top: ${theme.spacing_none};
    span > h3 {
      font-size: ${theme.typography_text_font_size_s2};
      margin-bottom: ${theme.spacing_none};
    }
    padding: ${theme.spacing_02};

    span {
      font-size: ${theme.typography_text_font_size_s1};
      margin-bottom: ${theme.spacing_01};
    }

    @media (min-width: ${theme.breakpoint_lg}) {
      padding: 1rem 1.5rem;
      span {
        font-size: ${theme.typography_text_font_size_s2};
      }
    }
  }
`,
);

export const StyledTable: StyledComponent<typeof Table, any> = styled(Table)(
  ({ theme }) => `
  &&&&& {
    thead th {
      padding: 1rem 0.5rem;
      span {
        font-size: ${theme.typography_text_font_size_s1};
      }
    }

    @media (min-width: ${theme.breakpoint_lg}) {
      thead th {
        padding: 1.5rem 1.5rem;
        span {
          font-size: ${theme.typography_text_font_size_s2};
        }
      }
    }
  }
`,
);
export const ItalicText: StyledComponent<typeof Text, any> = styled<typeof Text>(Text)`
&&&{
  font-style: italic;
}
`