/* eslint-disable react/no-array-index-key */
import React from 'react';

import {
  Main,
  Heading,
  ContentGroup,
  TableRow,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import testIds from 'client/testIds';
import { Helmet } from 'react-helmet';

import { CookiesPageContent } from './CookiesPage.config';
import { StyledTable, StyledTableCol } from './CookiesPage.Styled';


export default function CookiesPage(): React.JSX.Element {
  const {
    pageHeading,
    upperInfoPanel,
    lowerInfoPanel,
    externalLinksTable,
    partnersTable,
  } = useContent<CookiesPageContent>();

  return (
    <Main>
      <Helmet>
        <title>Cookies | Birmingham Midshires </title>
      </Helmet>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid={testIds.cookiesUpperInfoPanel.heading}
      >
        {pageHeading}
      </Heading>
      
      <InfoPanel
        infoPanel={upperInfoPanel}
        pageTestIds={testIds.cookiesUpperInfoPanel}
      />       
      
      <StyledTable
        headings={externalLinksTable.columns}
        aria-label={externalLinksTable.ariaLabel}
        responsive={false}
        width='fluid'
      >
        {externalLinksTable.rows.map((rowVal,index) => (
          <TableRow key={`externalLinksTable_${index}`}>
            <StyledTableCol heading={externalLinksTable.columns[0]}>
              {rowVal.textForRow1}
            </StyledTableCol>
            <StyledTableCol heading={externalLinksTable.columns[1]}>
              {rowVal.textForRow2}
            </StyledTableCol>
          </TableRow>

        ))}

      </StyledTable>
      
      <ContentGroup marginTop="05">
        <InfoPanel
          infoPanel={lowerInfoPanel}
          pageTestIds={testIds.cookiesLowerInfoPanel}
        />
      </ContentGroup>        

      <StyledTable
        headings={partnersTable.columns}
        aria-label={partnersTable.ariaLabel}
        responsive={false}
        width='fluid'
      >
        {partnersTable.rows.map((rowVal,index) => (
          <TableRow key={`partnersTable_${index}`}>
            <StyledTableCol heading={partnersTable.columns[0]}>
              {rowVal.textForRow1}
            </StyledTableCol>
            <StyledTableCol heading={partnersTable.columns[1]}>
              {rowVal.textForRow2}
            </StyledTableCol>
            <StyledTableCol heading={partnersTable.columns[2]}>
              {rowVal.textForRow3}
            </StyledTableCol>
          </TableRow>
        ))}
      </StyledTable>
    </Main>
  );
}
