import { TableCol, Table, Text } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledTable: StyledComponent<typeof Table, any> = styled<
  typeof Table
>(Table)`
  table-layout: fixed;
`;
export const ItalicText: StyledComponent<typeof Text, any> = styled<
  typeof Text
>(Text)`
  &&& {
    font-style: italic;
  }
`;

export const StyledListElement = styled.li`
  ${({ theme }: any) => theme.cssPrefix} {
    margin-bottom: ${({ theme }: any) => theme.spacing_05};
    margin-left: ${({ theme }: any) => theme.spacing_07};
  }
`;

type TableColProps = React.ComponentProps<typeof TableCol>;

type StyledTableColType = StyledComponent<
  typeof TableCol,
  any,
  TableColProps,
  never
>;
export const StyledTableCol: StyledTableColType = styled(TableCol)(
  ({ theme }) => `
  &&&&& {
    margin-top: ${theme.spacing_none};
    span > h3 {
      font-size: ${theme.typography_text_font_size_s2};
      margin-bottom: ${theme.spacing_none};
    }
    padding: ${theme.spacing_02};

    span {
      font-size: ${theme.typography_text_font_size_s1};
      margin-bottom: ${theme.spacing_01};
    }

    @media (min-width: ${theme.breakpoint_lg}) {
      padding: 1rem 1.5rem;
      span {
        font-size: ${theme.typography_text_font_size_s2};
      }
    }
  }
`,
);
